export function helperDateReverese(date_) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }

  var d = new Date(date_);
  console.log("Incoming date", d);

  const datePart = [pad(d.getFullYear()), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
  const timePart = [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(":");

  return `${datePart}, ${timePart}`;
}
