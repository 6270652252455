import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import InputField from "../Input/InputFields";
import dragIcon from "../../Assets/filedrag2.png";
import { base_url, token_api } from "../../Utils/Network";
import axios from "axios";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomSnackBar from "../CustomSnackBar/CustomSnackBar";

function ProductListingDialog({
  open,
  handleClose = () => {},
  data,
  setSnackbarData,
}) {
  const { token_data } = useContext(UserCredsContext);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [bookNowVlaue, setBookNowValue] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [couponError, setCouponerror] = useState({})
  const [paymentDetails, setPaymentDetails] = useState({
    paymentMode: "",
    trasactionId: null,
  });
const[selectproductFirst, setSelectproductFirst] = useState(false)
  const [couponCode, setCouponCode] = useState();
  const [codePrice, setCodePrice] = useState()
  const [paymentId, setPaymentId] = useState("");
  const [ppData, setPPdata] = useState([]);
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  //   const [snackbarData, setSnackbarData] = React.useState({
  //     message: "",
  //     open: false,
  //     responseType: "",
  //   });

  useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = () => {
    token_api
      .get(`/counseling/products`)
      .then((res) => {
        // console.log(res?.data, "getProductDetails");
        setPPdata(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleProductSelection = (productId) => {
    setSelectedProducts((prevProducts) => {
      const productExists = prevProducts.find(
        (product) => product.product_id === productId
      );

      if (productExists) {
        // Deselect product
        return prevProducts.filter(
          (product) => product.product_id !== productId
        );
      } else {
        // Select product
        const productDetails = ppData.find((v) => v.id === productId);
        return [
          ...prevProducts,
          {
            product_id: productId,
            quantity: 1,
            // payment_amount: parseFloat(productDetails.selling_price),
            // payment_mode: "credit_card", // or default to any mode you want
          },
        ];
      }
    });
  };

  const handleIncrement = (productId) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.product_id === productId
          ? {
              ...product,
              quantity: product.quantity + 1,
              //   payment_amount:
              //     product.payment_amount +
              //     parseFloat(
              //       ppData.find((v) => v.id === productId).selling_price
              //     ),
            }
          : product
      )
    );
  };

  const handleDecrement = (productId) => {
    setSelectedProducts((prevProducts) =>
      prevProducts
        .map((product) =>
          product.product_id === productId
            ? {
                ...product,
                quantity: product.quantity - 1,
                // payment_amount:
                //   product.payment_amount -
                //   parseFloat(
                //     productdetails.find((v) => v.id === productId).selling_price
                //   ),
              }
            : product
        )
        .filter((product) => product.quantity > 0)
    );
  };

  useEffect(() => {
    const total = selectedProducts.reduce((sum, product) => {
      const productDetails = ppData.find((v) => v.id === product.product_id);
      return sum + product.quantity * parseFloat(productDetails.selling_price);
    }, 0);

    setTotalPrice(total);
  }, [selectedProducts]);


  // console.log(selectedProducts, totalPrice, "Selected Products");

  const handelpurchase = () => {
    // debugger
    

    const purchseData = {
      user_id: data.user_detail.id,
      products: selectedProducts ,
    };

    token_api
      .post(`/counseling/product-purchase/process_order/`, purchseData)
      .then((res) => {
        // console.log(res?.data);
        setPaymentId(res?.data?.data?.payment_id);
        setBookNowValue(2);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(purchseData, "handelpurchase");
  };

  const handelClearClose = () => {
    handleClose();
    setSelectedProducts([]);
    setTotalPrice();
    setPaymentDetails({
      paymentMode: "",
      trasactionId: null,
    });
    setFileName("");
    setBookNowValue(0);
    setPaymentId();
    setCodePrice();
    setCouponCode();
    setCouponerror();
    setSelectproductFirst(false)
  };

  //  2nd Popup Logic

  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setPaymentDetails({
      ...paymentDetails,
      [name]: value,
    });
  };

  // console.log(paymentDetails, "handleChangeINput");

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInput.current.click();
    // console.log(fileInput, "jhdfjhjgf");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    // console.log(e.target?.files, "gadgas");
    if (e?.target?.files) {
      setFileName(e.target.files[0]);

      // file = e.target.files[0];
    } else if (e?.dataTransfer?.files) {
      setFileName(e.dataTransfer.files[0]);

      // file = e.dataTransfer.files[0];
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      setFile(reader.result);
    };
  };

  const validate = (value) => {
    const errors = {
      error: false,
    };
    if (paymentDetails) {
      if (!paymentDetails.paymentMode) {
        errors.paymentMode = "Enter Payment Mode";
        errors.error = true;
      }
      if (!paymentDetails.trasactionId) {
        errors.trasactionId = "Enter Trasaction Id";
        errors.error = true;
      }

      setFormErrors(errors);
      return errors;
    }
  };
  const handelpurchaseSubmit = (id) => {
    // debugger
    // console.log(id, paymentDetails, fileName,paymentId, "handelpurchaseSubmit");
    // debugger
    let validateResults = validate(paymentDetails);

    const { error } = validateResults;
    if (error) return;
    var studentBulkAp = new FormData();
    studentBulkAp.append("payment_mode", paymentDetails?.paymentMode);
    studentBulkAp.append("file", fileName);
    studentBulkAp.append("transaction_id", paymentDetails?.trasactionId);

    if (id) {
      axios({
        method: "patch",
        url: `${base_url}counseling/product-purchase/${id}/update_payment_status/`,
        data: studentBulkAp,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token_data.access}`,
        },
      })
        .then((response) => {
          handelClearClose();
          setSnackbarData({
            message: "Mindgraph Report details updated successfully",
            open: true,
            responseType: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });

      //   setOpen(false);
      return;
    } else {
      alert("File not Uploaded");
    }
  };

  const handleChangeINCoupon = (e) => {
    const { name, value } = e.target;
    setCouponCode(value);
  };

  const applyCoupon = () => {
    if (couponCode ) {
      const newData = {
        coupon_code: couponCode,
        institute_id: data?.institute == null ? null : data?.institute?.id,
      };
      token_api
        .post(`/counseling/product-purchase/apply_coupon/`, newData )
        .then((res) => {
          // debugger
          const errors = {
            error: false,
          };
          if(res?.status === 404 || res?.status === 400) {
            // console.log(res , "ejiwourdsnfksajdfksdjf");
            
            errors.couponCode = res?.data;
            errors.error= true;
            errors.color="error"
            setCouponerror(errors)
          //  return errors
          } else if(res?.status === 200 || res?.status === 201 || res?.status === 204){
            

            setCodePrice(res?.data?.data)
          //  return errors
          }
        
        })
        .catch((err) => {
          console.log(err);
          // console.log(err?.data , "ejiwourdsnfksajdfksdjf");
          const errors = {
            error: false,
          };
          errors.couponCode = "Invalid Code";
            errors.error= true;
            errors.color="error"
            setCouponerror(errors)
        });
    }
  };

  useEffect(() => {
    if(codePrice?.success){
      const aftercoupon = totalPrice- codePrice?.discount

      setTotalPrice(aftercoupon)
    }
  }, [codePrice])
  return (
    <Dialog
      open={open}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "750px",
          //   padding :" 0 40px",
        },
      }}
    >
      <DialogContent style={{ padding: "20px 40px" }}>
        <div style={{ textAlign: "right" }}>
          <CloseOutlined
            style={{ cursor: "pointer" }}
            onClick={handelClearClose}
          />
        </div>
        {bookNowVlaue == 0 && (
          <>
            <div className={styles.studentInfoBox}>
              <h3 className={styles.studentname}>
                {data?.user_detail?.full_name}
              </h3>
              <h4 className={styles.schoolname}>
                {data?.institute == null ? "School Name" : data?.institute?.name}
              </h4>
              <p className={styles.class}>{data?.grade}</p>
            </div>
            <div>
              <div className={styles.tableheading}>
                <h3 className={styles.col1}>Product</h3>
                <h3 className={styles.col2}>Price</h3>
                <h3 className={styles.col3}>Quantity</h3>
              </div>
              {ppData.map((v, index) => {
                const isSelected = selectedProducts.some(
                  (product) => product.product_id === v.id
                );
                // console.log(v, "eur89ijdknakjsdk");

                return (
                  <div
                    className={`${styles.tableheading2} ${
                      isSelected ? styles.selectedProduct : ""
                    }`}
                    key={v.id}
                    onClick={() => handleProductSelection(v.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={`${styles.productBox} ${styles.col1}`}>
                      <div className={styles.productIMgBox}>
                        <img src={v.icon} alt={v.product_name} />
                      </div>
                      <div>
                        <h3 className={styles.productName}>{v.product_name}</h3>
                        <p className={styles.productTimeDe}>
                          {!v.qty
                            ? `${v?.validity} Days `
                            : selectedProducts
                            ? ` Session`
                            : ""}{" "}
                        </p>
                      </div>
                    </div>
                    <div className={styles.col2}>
                      <h6 className={styles.MRP}> ₹{v.mrp}</h6>
                      <h5 className={styles.selling_price}>
                        ₹ {v.selling_price}
                      </h5>
                    </div>
                    <div className={styles.col3}>
                      {v.qty ? (
                        <div className={styles.quantityBtns}>
                          <div
                            className={styles.Buttons}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDecrement(v.id);
                            }}
                          >
                            -
                          </div>
                          <span>
                            {selectedProducts.find(
                              (product) => product.product_id === v.id
                            )?.quantity || 0}
                          </span>
                          <div
                            className={styles.Buttons}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleIncrement(v.id);
                            }}
                          >
                            +
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className={styles.couponinputField}>
              <InputField
                label={"Coupon code"}
                onChange={handleChangeINCoupon}
                name="Coupon Code"
                value={couponCode}
                error={couponError?.error}
                helperText={couponError?.couponCode}
                className={styles.inputInner}
                style={{ width: "100%" }}
              />
              

              <Button
                className={styles.ApplyBtn}
                variant="contained"
                onClick={applyCoupon}
              >
                Apply
              </Button>
            </div>

            {
              codePrice?.success && <p style={{color:"green"}}>{codePrice?.message}</p>
            }
            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>Total Amount</h2>
              <h2> ₹ {totalPrice}</h2>
            </div>
            {selectproductFirst && <p style={{color:"red"}}>Select Product</p> }
            <div>
              <Button
                onClick={() => handelpurchase()}
                className={styles.Booknow}
                variant="contained"
              >
                Book Now
              </Button>
            </div>
          </>
        )}

        {bookNowVlaue == 2 && (
          <>
            <div className={styles.inputField}>
              <InputField
                label={"Payment Mode"}
                onChange={handleChangeINput}
                name="paymentMode"
                value={paymentDetails?.paymentMode}
                error={formErrors?.paymentMode}
                helperText={formErrors?.paymentMode}
                className={styles.inputInner}
                style={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputField}>
              <InputField
                label={"Transaction Id"}
                onChange={handleChangeINput}
                name="trasactionId"
                value={paymentDetails?.trasactionId}
                error={formErrors?.trasactionId}
                helperText={formErrors?.trasactionId}
                style={{ width: "100%" }}
              />
            </div>

            <div className={styles.top}>
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className={styles.dropBoxStyles}
              >
                <div className={styles.flexStyleDropBox} onClick={handleClick}>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img src={dragIcon} alt="dradanddrop" />
                    <p>Drag & Drop the Files or click here</p>
                  </p>
                  <p>pdf,jpg,png,txt</p>
                  <input
                    ref={fileInput}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleDrop}
                    accept=".pdf,.jpg,.jpeg,.png,"
                  ></input>
                </div>
              </div>
            </div>
            {fileName && !file && (
              <p
                style={{
                  color: "green",
                  margin: "0 auto",
                  marginBottom: "10px",
                  width: "90%",
                }}
              >
                File {fileName?.name} is uploaded.
              </p>
            )}
            <div>
              <Button
                onClick={() => handelpurchaseSubmit(paymentId)}
                className={styles.Booknow}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ProductListingDialog;

const productdetails = [
  {
    id: 1,
    created: "2024-08-23T20:15:27.840359",
    modified: "2024-08-23T20:15:27.840378",
    deleted: false,
    product_name: "Alphaguide",
    mrp: "500.00",
    selling_price: "400.00",
    gst: 18,
    category: "book_test",
    description: "interest",
    validity: 120,
    icon: "https://lcarro-staging.s3.amazonaws.com/files/product_icons/asian-girl-anime-avatar-ai-art-photo.jpg",
    active: true,
    qty: false,
  },
  {
    id: 2,
    created: "2024-08-30T21:17:35.686182",
    modified: "2024-08-30T21:17:35.686204",
    deleted: false,
    product_name: "Career Counselling",
    mrp: "500.00",
    selling_price: "400.00",
    gst: 5,
    category: "book_slot",
    description: "book slot product",
    validity: 30,
    icon: "https://lcarro-staging.s3.amazonaws.com/files/product_icons/travel.jpg",
    active: true,
    qty: true,
  },
  {
    id: 3,
    created: "2024-08-30T21:17:35.686182",
    modified: "2024-08-30T21:17:35.686204",
    deleted: false,
    product_name: "MindGrapher",
    mrp: "400.00",
    selling_price: "300.00",
    gst: 5,
    category: "book_slot",
    description: "book slot product",
    validity: 30,
    icon: "https://lcarro-staging.s3.amazonaws.com/files/product_icons/travel.jpg",
    active: true,
    qty: true,
  },
];
