import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./mindgrapher.module.css";
import { Autocomplete, Drawer, TextField } from "@mui/material";
import { base_url, open_api } from "../../Utils/Network";
import dragIcon from "../../Assets/filedrag.png";
import axios from "axios";
import { Button } from "@mui/material";
import { useState } from "react";
import { token_api } from "../../Utils/Network";
import { useEffect } from "react";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomSnackBar from "../CustomSnackBar/CustomSnackBar";
import SearchAndDropdown from "../SearchAndDropdown/SearchAndDropdown";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const MindgrapherDrawerNew = ({
  handleCloseModal = () => {},
  open,
  Id,
  getAllSchoolListing = () => {},
}) => {
  //   console.log(Id, "hefuehwf");
  const fileInput = React.useRef(null);
  const interestInput = React.useRef(null);
  const [subjectList, setSubjectList] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [fileName2, setFileName2] = useState(null);
  const [file2, setFile2] = useState(null);
  const [active, setActive] = useState(null);
  const [preData, setPreData] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [error, setError] = useState("");
  const [mindgraphDetails, setMindGraphDetails] = React.useState({
    student_id: "",

    name: null,
  });
  const { token_data } = React.useContext(UserCredsContext);
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    open: false,
    responseType: "",
  });
  const [accForm, setaccForm] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [required, setRequired] = useState();
  const [fileUplodeResponce, setFileUploadeResponce] = useState({});
  const [serviceBookingSeleted, setServiceBookingSelected] = useState({
    full_name: "",
    id: "",
    phone: "",
  });
  const [studentDetails, setStudentDetails] = useState({});
  const [tabValue, setTabValue] = React.useState(0);
  const [backDropOpen, setBackDropOpen]= useState(false)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getPreData = (id) => {
    // setPreData([])
    token_api
      .get(`counseling/mindreports/${id}/`)
      .then((response) => {
        setPreData(response.data?.data);
      })
      .catch((error) => console.log(error));
  };

  console.log(preData, "kjskjhs");
  useEffect(() => {
    if (Id) {
      getPreData(Id);
    }
  }, [Id]);

  //   const getDataList = () => {
  //     token_api
  //       .get(`counseling/dashboard/students`)
  //       .then((response) => {
  //         let newArray = response.data?.data?.map((sub, index) => {
  //           return {

  //             id: sub?.user_detail?.id,

  //             title: sub?.user_detail?.first_name,
  //           };
  //         });
  //         setDropList(newArray);

  //         console.log(newArray, "jdhnfjkhskjf");
  //       })
  //       .catch((error) => console.log(error));
  //   };

  //   console.log(dropList, "shdfhf");
  //   React.useEffect(() => {
  //     // if (Id) {
  //     getDataList();

  //     // }
  //   }, []);

  React.useEffect(() => {
    setMindGraphDetails({
      student_id: "",
      name: "",
      // subject_id: "",
    });
  }, [open]);
  const handleAutoComplete = (e, val) => {
    console.log("AUTOTOTO", val);

    setMindGraphDetails({
      ...mindgraphDetails,
      subject_id: val?.id,
      name: val,
    });
  };

  const handleClick = () => {
    fileInput.current.click();
    console.log(fileInput, "jhdfjhjgf");
  };

  const handleClick2 = () => {
    interestInput.current.click();
  };
  const handleDrop = (e, fileType) => {
    e.preventDefault();

    let selectedFile = null;
    if (e?.target?.files) {
      selectedFile = e.target.files[0];
    } else if (e?.dataTransfer?.files) {
      selectedFile = e.dataTransfer.files[0];
    }

    if (selectedFile) {
      if (fileType === "EdfFILE") {
        setFileName(selectedFile);
        // setFile(selectedFile); // Assuming setFile is used for the first file
      } else if (fileType === "CsvFILE") {
        setFileName2(selectedFile);
        // setFile2(selectedFile); // Save the file to file2 state
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (fileType === "EdfFILE") {
          setFile(reader.result);
        } else if (fileType === "CsvFILE") {
          setFile2(reader.result); // Read and save file2's data
        }
      };
      //   reader.readAsText(selectedFile); // Reads the file content
    }
  };

  console.log(fileName, fileName2?.name, file2, "fnasiduhfisajdfkbakhlm");

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);

  // useEffect(() => {
  //     if (isListening) {

  //         handleListen();
  //     }
  // }, [isListening]);

  const handleListen = (id) => {
    console.log(id, "sjsdhfa");
    if (id) {
      console.log("something");
      if (isListening) {
        // debugger
        mic.start();
        mic.onend = () => {
          console.log("continue..");
          mic.start();
        };
      } else {
        mic.stop();
        mic.onend = () => {
          console.log("Stopped Mic on Click");
        };
      }
      mic.onstart = () => {
        console.log("Mics on");
      };

      mic.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        setNote(transcript);

        // Update the specific studentDream item based on the id
        setStudentDream((prevStudentDream) =>
          prevStudentDream.map((item) =>
            item.id === id ? { ...item, value: transcript } : item
          )
        );

        mic.onerror = (event) => {
          console.log(event.error);
        };
      };
    } else {
      console.log("something went wrong");
    }
  };
  // console.log(studentDream, "djkhfyiau8979979");
  const [card, setCard] = useState(false);

  const [studentDream, setStudentDream] = useState([
    {
      id: 1,
      name: "interest and aptitude result",
      value: "",
      // file:"",
    },
    {
      id: 2,
      name: "synchronization",
      value: "",
    },
    {
      id: 3,
      name: "visible improvements",
      value: "",
    },
    {
      id: 4,
      name: "conclusion",
      value: "",
    },
  ]);

  useEffect(() => {
    setTabValue(0);
    if (preData?.id) {
        setTabValue(1);
      setStudentDream([
        {
          id: 1,
          name: "interest and aptitude result",
          value: preData.interest_and_aptitude_result
            ? preData.interest_and_aptitude_result
            : "",
        },
        {
          id: 2,
          name: "synchronization",
          value: preData.synchronization || "",
        },
        {
          id: 3,
          name: "visible improvements",
          value: preData.visible_improvements || "",
        },
        { id: 4, name: "conclusion", value: preData.conclusion || "" },
      ]);
      const name =
        (preData?.user?.first_name ? preData?.user?.first_name : "") +
        " " +
        (preData?.user?.last_name ? preData?.user?.last_name : "");
      // setMindGraphDetails({ name: name, id: preData?.user?.id });
      setServiceBookingSelected({
        first_name: preData?.user?.first_name ? preData?.user?.first_name : "",
        last_name: preData?.user?.last_name ? preData?.user?.last_name : "",
        id: preData?.user?.id ? preData?.user?.id : "",
        phone: preData?.user?.phone ? preData?.user?.phone : "",
      });
    }else{
        setTabValue(0);
    }
  }, [preData]);

  const handleClearStates = () => {
    // debugger
    // if (!id) {
    setStudentDream([
      { id: 1, name: "interest and aptitude result", value: "" },
      { id: 2, name: "synchronization", value: "" },
      { id: 3, name: "visible improvements", value: "" },
      { id: 4, name: "conclusion", value: "" },
    ]);
    setServiceBookingSelected({
      first_name: "",
      last_name: "",
      id: "",
      phone: "",
    });
    setPreData([]);
    setTabValue(0)
    //   /
  };

  const handleStudentDreamSubmit = (e) => {
    e.preventDefault();
    setCard(true);
    let data = {};
  };

  const handleChange = (e, id) => {
    const updatedStudentDream = studentDream.map((item) =>
      item.id === id ? { ...item, value: e.target.value } : item
    );
    setStudentDream(updatedStudentDream);
  };

  const handleMicClick = (id) => {
    setActive(id);
    handleListen(id);
    setIsListening(!isListening);
  };

  const postData = (id) => {
    // if(!id) return setError("Select Student")

    var formData = new FormData();

    // if (studentDream[0].value || studentDream[1].value || studentDream[2].value || studentDream[3].value) {
    //     formData.append("user_id", id);
    //     formData.append("file", fileName);
    //     formData.append("interest_and_aptitude_result", studentDream[0].value);
    //     formData.append("synchronization", studentDream[1].value);
    //     formData.append("visible_improvements", studentDream[2].value);
    //     formData.append("conclusion", studentDream[3].value);
    // }
    // Conditionally append each field if there is data present
    if (studentDream[0]?.value) {
      formData.append("interest_and_aptitude_result", studentDream[0].value);
    }
    if (studentDream[1]?.value) {
      formData.append("synchronization", studentDream[1].value);
    }
    if (studentDream[2]?.value) {
      formData.append("visible_improvements", studentDream[2].value);
    }
    if (studentDream[3]?.value) {
      formData.append("conclusion", studentDream[3].value);
    }

    // Append user ID and file if they are present
    formData.append("user_id", id);
    if (fileName) {
      formData.append("file", fileName);
    }
    const url = Id
      ? `${base_url}counseling/mindgraph/report2/${Id}/update_mindgrapher_report/`
      : `${base_url}counseling/mindgraph/report2/mind_grapher/`;

    const newMethod = Id ? "patch" : "post";

    axios({
      method: newMethod,

      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token_data.access}`,
      },
    })
      .then((response) => {
        console.log("MIndResponse", response);
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          // alert("File was uploaded successfully.");
          // handleClearStates();
          // setState(false);
          //   debugger

          setSnackbarData({
            message: "Mindgraph Report details updated successfully",
            open: true,
            responseType: "success",
          });

          setFile(null);
          setFileName("");
          setStudentDream([
            { id: 1, name: "interest and aptitude result", value: "" },
            { id: 2, name: "synchronization", value: "" },
            { id: 3, name: "visible improvements", value: "" },
            { id: 4, name: "conclusion", value: "" },
          ]);
        }
        handleCloseModal();
        getAllSchoolListing({ q: "", page: 1 });
      })

      .catch((err) => {
        // alert("Error in uploading file, please try again.");
        setSnackbarData({
          message: "Error while updating mindgraph details",
          open: true,
          responseType: "error",
        });
      });

    const timeOut = setTimeout(handleOpen, 3000);
    function handleOpen() {
      setSnackbarData({
        message: "",
        open: false,
        responseType: "success",
      });
    }
  };

  const handleAutocompleteChangeforServiceBooking = (selectedValues) => {
    setServiceBookingSelected(selectedValues);
    setStudentDetails(selectedValues);
  };

  console.log(studentDetails, "handleAutocompleteChangeforServiceBooking");

  const postFiles = (id) => {
    setBackDropOpen(true)
    var formData = new FormData();
    // Append user ID and file if they are present
    formData.append("user_id", id);
    if (fileName) {
      formData.append("file", fileName);
    }
    if (fileName2) {
      formData.append("interest_file", fileName2);
    }

    axios({
      method: "post",

      url: `${base_url}counseling/mindgraph/report2/mind_grapher_with_interest/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token_data.access}`,
      },
    })
      .then((response) => {
        console.log("MIndResponse", response);
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          // alert("File was uploaded successfully.");
          // handleClearStates();
          // setState(false);
          //   debugger

          // setSnackbarData({
          //   message: "Mindgraph Report details updated successfully",
          //   open: true,
          //   responseType: "success",
          // });

          // setFile(null);
          // setFileName("");
          setFileUploadeResponce(response?.data?.data);
          setPreData(response?.data?.data);
          setTabValue(1);

          // setStudentDream([
          //   { id: 1, name: "interest and aptitude result", value: "" },
          //   { id: 2, name: "synchronization", value: "" },
          //   { id: 3, name: "visible improvements", value: "" },
          //   { id: 4, name: "conclusion", value: "" },
          // ]);
        }
        //   handleCloseModal();
        getAllSchoolListing({ q: "", page: 1 });
        setBackDropOpen(false)
      })

      .catch((err) => {
        // alert("Error in uploading file, please try again.");
        setSnackbarData({
          message: "Error while updating mindgraph details",
          open: true,
          responseType: "error",
        });
        setBackDropOpen(false)
      });
  };

  useEffect(() => {
    if (fileUplodeResponce) {
      console.log(fileUplodeResponce, "fileUplodeResponce");

      setStudentDream([
        {
          id: 1,
          name: "interest and aptitude result",
          value: fileUplodeResponce.interest_and_aptitude_result || "",
        },
        {
          id: 2,
          name: "synchronization",
          value: fileUplodeResponce.synchronization || "",
        },
        {
          id: 3,
          name: "visible improvements",
          value: fileUplodeResponce.visible_improvements || "",
        },
        {
          id: 4,
          name: "conclusion",
          value: fileUplodeResponce.conclusion || "",
        },
      ]);
    }
  }, [fileUplodeResponce]);

  const handleUploadText = (studentId, fileId) => {
    const sendparameters = {
      interest_and_aptitude_result:
        fileUplodeResponce.interest_and_aptitude_result,
      synchronization: fileUplodeResponce.synchronization,
      visible_improvements: fileUplodeResponce.visible_improvements,
      conclusion: fileUplodeResponce.conclusion,
    };
    token_api
      .patch(
        `counseling/mindgraph/report2/${fileId}/update_mindgrapher_report/`,
        sendparameters
      )
      .then((response) => {
        console.log("MIndResponse", response);
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          debugger;
          setSnackbarData({
            message: "Mindgraph Report details updated successfully",
            open: true,
            responseType: "success",
          });

          setFile(null);
          setFile2(null);
          setFileName("");
          setFileName2("");
          //   setFileUploadeResponce(response?.data?.data)
          setTabValue(0);

          setStudentDream([
            { id: 1, name: "interest and aptitude result", value: "" },
            { id: 2, name: "synchronization", value: "" },
            { id: 3, name: "visible improvements", value: "" },
            { id: 4, name: "conclusion", value: "" },
          ]);
        }
        handleCloseModal();
        getAllSchoolListing({ q: "", page: 1 });
      })

      .catch((err) => {
        // alert("Error in uploading file, please try again.");
        setSnackbarData({
          message: "Error while updating mindgraph details",
          open: true,
          responseType: "error",
        });
      });
  };

//   console.log(tabValue, "asdadAEQ2EWSDAS");
  

  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            <Drawer
              anchor={anchor}
              open={open}
              onClose={() => {
                handleClearStates();

                handleCloseModal(false);
              }}
            >
              <Typography sx={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    marginTop: "50px",
                    visibility: tabValue === 0 ? "visible" : "hidden",
                  }}
                  onClick={
                    tabValue === 0
                      ? () => {
                          handleClearStates();
                          handleCloseModal();
                        }
                      : ""
                  }
                >
                  <CloseIcon />
                </div>
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#032C6B", // Active tab line color
                        },
                      }}
                    >
                      <Tab
                        label="STEP 1"
                        {...a11yProps(0)}
                        sx={{
                          color: "#032C6B", // Tab text color
                          "&.Mui-selected": {
                            color: "#032C6B", // Active tab text color
                          },
                        }}
                      />
                      <Tab
                        label="STEP 2"
                        {...a11yProps(1)}
                        sx={{
                          color: "#032C6B", // Tab text color
                          "&.Mui-selected": {
                            color: "#032C6B", // Active tab text color
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabValue} index={0}>
                    <div className={styles.con}>
                      <SearchAndDropdown
                        error={error}
                        value={serviceBookingSeleted}
                        onChange={handleAutocompleteChangeforServiceBooking}
                      />

                      {error && <p>{error}</p>}
                      <h4 style={{ marginBottom: "0px" }}>Upload Edf File</h4>
                      <div className={styles.top}>
                        <div
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, "EdfFILE")}
                          className={styles.dropBoxStyles}
                        >
                          <div
                            className={styles.flexStyleDropBox}
                            onClick={handleClick}
                          >
                            <p
                              style={{
                                width: "100%",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <img src={dragIcon} alt="dradanddrop" />
                              <p>Drag & Drop the Files or click here</p>
                            </p>
                            <input
                              ref={fileInput}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => handleDrop(e, "EdfFILE")}
                              accept=".edf"
                            ></input>
                          </div>
                        </div>
                        {fileName && !file && (
                          <p
                            style={{
                              color: "green",
                              margin: "0 auto",
                              marginBottom: "10px",
                              width: "90%",
                            }}
                          >
                            File {fileName?.name} is uploaded.
                          </p>
                        )}
                      </div>
                      <h4 style={{ marginBottom: "0px" }}>
                        Upload Interest CSV File
                      </h4>
                      <div className={styles.top}>
                        <div
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, "CsvFILE")}
                          className={styles.dropBoxStyles}
                        >
                          <div
                            className={styles.flexStyleDropBox}
                            onClick={handleClick2}
                          >
                            <p
                              style={{
                                width: "100%",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <img src={dragIcon} alt="dradanddrop" />
                              <p>Drag & Drop the Files or click here</p>
                            </p>
                            <input
                              ref={interestInput}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => handleDrop(e, "CsvFILE")}
                              accept=".csv"
                            ></input>
                          </div>
                        </div>
                        {fileName2 && !file2 && (
                          <p
                            style={{
                              color: "green",
                              margin: "0 auto",
                              marginBottom: "10px",
                              width: "90%",
                            }}
                          >
                            File {fileName2?.name} is uploaded.
                          </p>
                        )}
                      </div>
                      <Button
                        style={{
                          backgroundColor:"#032C6B",
                          width: "100%",
                        }}
                        type="submit"
                        // size="small"
                        variant="contained"
                        onClick={() => {
                          // postData(serviceBookingSeleted?.id);
                          postFiles(serviceBookingSeleted?.id);
                          handleClearStates();
                          setFileName("");
                        }}
                      >
                        Save & Proceed
                      </Button>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    {/* <Typography> */}
                    <div className={styles.con}>
                      <div className={styles.shwostudentdata}>
                        <div>
                        <h4  style={{margin:"10px 0", textTransform:"capitalize"}}>
                          {preData?.user?.first_name} {preData?.user?.last_name}
                        </h4>
                        <span>{preData?.user?.grade}</span>
                        </div>
                        <p className={styles.idtext}>{preData?.user?.id}</p>
                      </div>
                      <div className={styles.cardContainer}>
                        {studentDream?.map((items) => {
                          return (
                            <>
                              <div className={styles.editStyle}>
                                <span className={styles.font20}>
                                  {items.name}
                                </span>
                                <div
                                  className={styles.micCon}
                                  onClick={() => handleMicClick(items.id)}
                                >
                                  {active === items.id && !isListening ? (
                                    <MicIcon sx={{ color: "#02f733" }} />
                                  ) : (
                                    <MicOffIcon sx={{ color: "#f71302" }} />
                                  )}
                                </div>

                                <div style={{ display: "flex" }}></div>
                              </div>
                              {card == false ? (
                                <>
                                  <form onSubmit={handleStudentDreamSubmit}>
                                    <div className={styles.top}>
                                      <TextField
                                        fullWidth
                                        onChange={(e) =>
                                          handleChange(e, items.id)
                                        }
                                        // onClick={() => { setActive(items.id) }}

                                        name={items?.name}
                                        value={items?.value}
                                        multiline
                                        required
                                        rows={4}
                                        // disabled={!active}
                                      />
                                    </div>
                                  </form>
                                </>
                              ) : (
                                <>
                                  <div className={styles.showCard}>
                                    {studentDream && studentDream}
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>

                      <Button
                        style={{
                            backgroundColor:"#032C6B",
                          padding: "10px 40px",
                          width: "100%",
                        }}
                        type="submit"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          handleUploadText(
                            serviceBookingSeleted?.id,
                            fileUplodeResponce?.id
                          );
                          //   postData(serviceBookingSeleted?.id);
                          //   handleClearStates();
                          //   setFileName("");
                        }}
                      >
                        Save & Proceed
                      </Button>
                    </div>
                    {/* </Typography> */}
                  </CustomTabPanel>
                </Box>
              </Typography>
            </Drawer>
          </React.Fragment>
        ))}
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropOpen}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      <CustomSnackBar
        message={snackbarData.message}
        openSnack={snackbarData.open}
        severity={snackbarData.responseType}
      />
    </>
  );
};

export default MindgrapherDrawerNew;
